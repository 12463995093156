@import '../../../scss/variables.scss';

.error {
	padding: 9px 11px;
	border-radius: 4px;
	font-size: 14px;
	line-height: 1;
	color: $primary-color;
	background: $error-background;
}
