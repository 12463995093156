@import '../../../scss/variables.scss';

.input-group {
	margin-bottom: 20px;

	&__label {
		display: inline-block;
		margin: 0 0 11px;
		font-size: $primary-font-size-lg;
		line-height: $primary-line-height;
		color: $secondary-color;
	}

	&__inputs {
		display: flex;
	}

	&__before-addon {
		margin-right: 15px;
	}

	&__content {
		position: relative;
		width: 100%;
	}

	&__input-container {
		position: relative;
		display: flex;
		padding: 1px;
	}

	&__input {
		width: 100%;
		padding: 11px 10px;
		z-index: 2;
		font-size: $primary-font-size;
		line-height: $primary-line-height;
		border-radius: $primary-border-radius;
		background: transparent;
		border: 0;
		outline: none;
	}

	&__input:focus + &__input-outline {
		border-color: $input-focus;
	}

	&__input-outline {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		border: 1px solid $secondary-color;
		border-radius: $primary-border-radius;
		outline: none;
		transition: 0.15s border;
	}

	&__after-addon {
		position: absolute;
		padding: 17px 10px;
		font-size: $primary-font-size-xs;
		color: $secondary-color;
		top: 0;
		left: 100%;
	}

	&__error {
		display: none;
		padding: 9px 11px;
		border-radius: $primary-border-radius;
		font-size: $primary-font-size-sm;
		line-height: $primary-line-height;
		color: $primary-color;
		background: $error-background;

		margin-top: 3px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: -3px;
			left: 10px;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-bottom: 3px solid $error-background;
		}
	}

	&__addon {
		z-index: 2;
		padding: 14px 10px;
		font-size: $primary-font-size-xs;
		line-height: $primary-line-height;
		color: $secondary-color;
		border: 1px solid transparent;
		background: transparent;
		cursor: pointer;
		transition: box-shadow .2s ease;
		outline: none;

		&:hover {
			color: $input-addon-hover;
		}

		&:last-child {
			border-top-right-radius: $primary-border-radius;
			border-bottom-right-radius: $primary-border-radius;
		}
	}

	&--error {
		.input-group {
			&__error {
				display: block;
			}

			&__input-container {
				border-color: $error-background;
			}
		}
	}

	&__info {
		margin-top: 5px;
		font-size: $primary-font-size-xs;
		line-height: $primary-line-height;
		color: $secondary-color;
	}

	&--godryv {
		.input-group__input:focus + .input-group__input-outline {
			border-color: $input-focus-go-dryv;
		}
	}

	&--hertz {
		.input-group__input {
			font-family: inherit;
		}

		.input-group__input-outline {
			border: 2px solid #FFC400;
			border-radius: 0;
		}

		.input-group__label {
			color: #000;
			font-weight: 700;
		}


		.input-group__input:focus + .input-group__input-outline {
			border-color: rgba(82,168,236,0.8);
			box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(82,168,236,0.6);
		}
	}
}
