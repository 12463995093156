@import '../../../scss/variables.scss';

.link {
	font-weight: 700;
	color: $secondary-color;
	text-decoration: underline;
	transition: 0.15s color;

	&:visited {
		color: $visited-color;
	}

	&:hover {
		color: $hover-color;
	}

	&:focus {
		outline: $hover-color auto;
	}

	&--light {
		font-weight: 300;
	}

	&--hertz {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		color: #000;
		text-decoration: none;

		&:visited {
			color: #000;
		}

		&:hover {
			color: #FFC400;
		}

		&:focus {
			outline: none;
		}
	}
}
