@import '../../scss/variables.scss';

.not-found {
	margin-top: 30px;
	max-width: 320px;
	font-size: $primary-font-size;
	line-height: 1;

	&__heading {
		margin-bottom: 10px;
		font-size: 30px;
		font-weight: 500;

		@media (min-width: $screen-md) {
			font-size: 40px;
		}
	}

	&__content {
		margin-bottom: 20px;
		font-size: 30px;
		font-weight: 300;
		line-height: 1.25;

		@media (min-width: $screen-md) {
			font-size: 40px;
		}
	}

	&__list {
		font-size: $primary-font-size;
		line-height: $primary-line-height;
		list-style-type: none;
		padding: 10px 0 10px;
	}

	&__list-item {
		padding: 10px 0;
	}

	&__list-heading {
		font-size: 18px;
		line-height: 22px;
	}
}
