@import '../../../scss/variables.scss';

.button {
	width: 100%;
	padding: 13px 38px;
	border: 2px solid transparent;
	border-radius: 10px;
	background: $button-background;
	font-family: inherit;
	font-size: $primary-font-size-lg;
	line-height: $primary-line-height;
	font-weight: 700;
	color: $primary-color;
	text-transform: uppercase;
	cursor: pointer;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
	transition: background .2s ease-out;

	&:hover {
		background: $hover-color;
	}

	&:focus {
		outline: none;
		border-color: $hover-color;
		box-shadow: 0 0 12px 0 $hover-color;
	}
}

.button--godryv {
	background: $button-background-go-dryv;

	&:hover {
		background: $hover-color-go-dryv;
	}

	&:focus {
		border-color: $hover-color-go-dryv;
		box-shadow: 0 0 12px 0 $hover-color-go-dryv;
	}
}

.button--hertz {
	font-family: inherit;
	display: block;
	margin-left: auto;
	background: #FFC400;
	box-shadow: none;
	width: 173px;
	border-radius: 0;
	color: #000;

	&:hover {
		color: #FFC400;
		background: #000;
		box-shadow: none;
	}

	&:focus {
		color: #FFC400;
		background: #000;
		box-shadow: none;
		border-color: #000;
	}
}
