@import 'scss/variables.scss';
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background: $primary-color;
	font-family: 'Roboto', sans-serif;
}
