$screen-xs: 320px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1900px;

$gradient-main-color1: #1E2430;
$gradient-main-color2: #3B414A;
$error-background: #F76363;
$button-background: #03BFD7;
$input-focus: #03BFD7;
$input-addon-hover: #42484F;
$hover-color: #007F9E;
$visited-color: #B2B2B2;
$primary-color: #FFF;
$secondary-color: #707070;

$button-background-go-dryv: #E1692B;
$hover-color-go-dryv: #BF6029;
$input-focus-go-dryv: #E1692B;
$primary-color-go-dryv: #333132;
$bg-color-go-dryv: #F8F8F8;

$primary-border-radius: 4px;
$primary-font-size: 18px;
$primary-font-size-lg: 16px;
$primary-font-size-sm: 14px;
$primary-font-size-xs: 12px;
$secondary-font-size-sm: 40px;
$secondary-font-size-xs: 30px;
$primary-line-height: 1;
