@import '../../../scss/variables.scss';

.powered-by {
	display: block;
	font-size: 12px;
	line-height: 1;
	text-align: center;
	color: $secondary-color;

	&--godryv {
		.powered-by__crg {
			display: none;
		}

		.powered-by__logo {
			width: 198px;
			height: 60px;
			margin: 0 auto 10px;
			background: url('./autoeurope_logo.svg') no-repeat;
			background-position: center center;
		}
	}

	&--hertz {
		display: flex;
		align-items: center;
		white-space: nowrap;
		font-size: 10px;
		line-height: 1;
		color: #000;

		@media (min-width: 1300px) {
			font-size: 12px;
		}

		.powered-by__crg {
			display: none;
		}

		.powered-by__logo {
			width: 130px;
			height: 60px;
			margin: 0 auto 10px;
			background: url('./autoeurope_logo.svg') no-repeat;
			background-position: center center;
			font-weight: 400;

			@media (min-width: 1300px) {
				width: 198px;
			}
		}

		a {
			padding-left: 5px;

			@media (min-width: 1300px) {
				padding-left: 15px;
			}
		}
	}
}
