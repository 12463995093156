@import '../../scss/variables.scss';

.sso-divider {
    color: $secondary-color;
    text-align: center;
    padding: 20px 0;
}

.sso-grid {
    padding: 10px 0;
}

.sso-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 5px;
    background-color: $primary-color;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
    max-width: 150px;
    margin: 0 auto;
}

.sso-button {
    width: 100%;
    height: 30px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    background-color: $primary-color;
    border-radius: 5px;
    border: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: $secondary-color;
}

.duo-logo {
    width: 27px;
    height: 100%;
    background: url('./SsoLogos/duo-logo.png') no-repeat center;
    background-size: contain;
}