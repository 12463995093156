.custom-layout {
	font-family: 'Mulish', sans-serif;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	&__header {
		position: relative;
		padding: 0 10px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			height: 7px;
			background: #FFC400;
		}

		@media (min-width: 550px) {
			height: 90px;
			padding: 0 25px;
		}

		@media (min-width: 768px) {
			height: 122px;
			padding: 0 70px;
		}

		@media (min-width: 970px) {
			padding: 0 140px;
		}
	}

	&__header-logo {
		flex: 0 0 70px;
		height: 100%;
		max-height: 47px;
		background: url('./Hertz.svg') no-repeat center;

		@media (min-width: 550px) {
			flex: 0 0 100px;
		}

		@media (min-width: 768px) {
			flex: 0 0 135px;
		}
	}

	&__header-slogan {
		flex: 0 0 190px;
		height: 100%;
		max-height: 54px;
		background: url('./Hertz-slogan.svg') no-repeat center;

		@media (min-width: 550px) {
			flex: 0 0 355px;
		}

		@media (min-width: 768px) {
			flex: 0 0 405px;
		}
	}

	&__header-abbreviation {
		color: #757678;
		line-height: 1;
	}

	&__container {
		display: flex;
		height: 100%;
		flex: auto;
	}

	&__heading {
		margin: 21px 0 30px;
		line-height: 1;
		font-size: 30px;
		font-weight: 700;
	}

	&__content {
		flex: 1 0 100%;
		padding: 0 30px;

		@media (min-width: 550px) {
			padding: 0;
		}

		@media (min-width: 970px) {
			flex: 1 0 40%;
		}
	}

	&__content-wrapper {
		max-width: 320px;
		margin: 160px auto 0;
	}

	&__intro {
		display: none;

		@media (min-width: 970px) {
			display: block;
			flex: 0 0 60%;
			background: url('./Hertz-intro-bg.png') no-repeat center;
			background-size: cover;
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: #F3F4F8;
		padding: 25px 0 0;

		@media (min-width: 550px) {
			padding: 45px 0;
		}

		@media (min-width: 970px) {
			flex-direction: row;
		}
	}

	&__footer-address {
		padding: 0 0 30px;

		@media (min-width: 768px) {
			padding: 0 50px 0 75px;
		}

		@media (min-width: 1300px) {
			padding: 0 100px 0 150px;
		}
	}

	&__footer-address > div:not(:first-child) {
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
	}

	&__footer-heading {
		font-size: 22px ;
		line-height: 33px ;
		font-weight: 700 ;
	}

	&__footer-container {
		display: block;
		padding: 0 30px;

		@media (min-width: 550px) {
			padding: 0 25px 0 75px;
		}

		@media (min-width: 768px) {
			display: flex;
			padding: 0;
		}
	}

	&__footer-email a {
		font-size: 18px;
		font-weight: 700 !important;
	}

	&__contacts {
		display: block;

		@media (min-width: 550px) {
			display: flex;
		}

		& > div {
			width: 200px;
		}

		div:first-child {
			margin-right: 40px;
		}
		a {
			display: block;
		}
	}

	&__crg {
		align-self: center;
		padding-right: 75px;

		@media (min-width: 1300px) {
			padding-right: 150px;
		}
	}
}
