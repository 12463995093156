@keyframes crg-svg-loader-dash-negative {
	0% { stroke-dashoffset: 300; }
	35% { stroke-dashoffset: 600; }
	100% { stroke-dashoffset: 600; }
}

@keyframes crg-svg-loader-dash-positive {
	0% { stroke-dashoffset: 300; }
	10% { stroke-dashoffset: 300; }
	35% { stroke-dashoffset: 250; }
	100% { stroke-dashoffset: 250; }
}

@keyframes crg-svg-loader-rotate {
	42.99% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes crg-svg-loader-appear {
	@for $i from 0 through 10 {
		#{$i * 1.25%} {
			transform: scale($i * 0.05 + 0.5);
			@if $i == 0 {
				opacity: 0;
			}
			@if $i == 10 {
				opacity: 1;
			}
		}
	}
}

@keyframes crg-svg-loader-move-circle-one {
	0% { opacity: 0; }
	36.4% { transform: translateX(35.3%) translateY(-5.5%) translateZ(0); opacity: 0; }
	36.5% { transform: translateX(28.1%) translateY(-4.5%) translateZ(0); opacity: 1; }
	42% { transform: translateX(0%) translateY(0) translateZ(0); opacity: 1; }
}

@keyframes crg-svg-loader-move-circle-two {
	0% { opacity: 0; }
	36.4% { transform: translateX(16.3%) translateY(37.2%) translateZ(0); opacity: 0; }
	36.5% { transform: translateX(12.9%) translateY(29.8%) translateZ(0); opacity: 1; }
	42% { transform: translateX(0%) translateY(0) translateZ(0); opacity: 1; }
}

@keyframes crg-svg-loader-move-circle-three {
	0% { opacity: 0; }
	36.4% { transform: translateX(-18.6%) translateY(-14.9%) translateZ(0); opacity: 0; }
	36.5% { transform: translateX(-14.4%) translateY(-12.6%) translateZ(0); opacity: 1; }
	42% { transform: translateX(0%) translateY(0) translateZ(0); opacity: 1; }
}

.crg-loader {
	width: 23%;
	margin: 0 auto;
}

.crg-svg-loader {
	height: 100%;
	width: 100%;
	overflow: initial;
	animation: crg-svg-loader-rotate 1.5s 2s linear infinite;

	&--color {
		fill:#8A8C8E;
	}

	&__line {
		stroke-width: 21;
		stroke-miterlimit: 10;
		stroke: #8A8C8E;
		fill: none;

		&--negative-offset {
			stroke-dasharray: 300;
			animation: crg-svg-loader-dash-negative 5s 1 forwards;
		}

		&--positive-offset {
			stroke-dasharray: 300;
			animation: crg-svg-loader-dash-positive 5s 1 forwards;
		}
	}

	&__circle-one {
		animation: crg-svg-loader-move-circle-one 5s 1 forwards;
	}

	&__circle-two {
		animation: crg-svg-loader-move-circle-two 5s 1 forwards;
	}

	&__circle-three {
		animation: crg-svg-loader-move-circle-three 5s 1 forwards;
	}

	&__circle-center {
		transform-origin: center;
		animation: crg-svg-loader-appear 5s 1 forwards;
	}
}